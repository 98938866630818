<template>
  <div class="Top">
    <div id="notificationWrap" class="contentsWidth mx-auto mt-4">

      <div class="row col-12">
        <p class="title">通知 </p>
      </div>
      <perfect-scrollbar id="notificationArea" class="row col-12 m-0">
        <table class="table p-0">
          <tbody>
            <tr v-for="(notification, kei, index) in notification" :key="index">
              <th>
                {{notification.dateFrom}}
              </th>
              <td class="icon">
                <b-badge variant="danger" v-if="notification.elapsed <= 7">新着</b-badge>
              </td>
              <td class="ml-3 newsText">{{notification.notification}}</td>
            </tr>
            <tr v-if="notification !== null && notification.length === 0">
              <td class="none-data text-left">通知はありません。</td>
            </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
    <div id="newsWrap" class="contentsWidth mx-auto mt-4">
      <div class="row col-12">
        <p class="title">お知らせ</p>
      </div>
      <perfect-scrollbar id="newsArea" class="row col-12 m-0">
        <table class="table p-0">
          <tbody>
            <tr v-for="(news, kei, index) in news" :key="index">
              <th>{{news.dateFrom}}</th>
              <td class="icon"></td>
              <td class="ml-3 newsText">{{news.news}}</td>
            </tr>
            <tr v-if="news !== null && news.length === 0">
              <td class="ml-3 text-left">お知らせはありません。</td>
            </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
    <div id="applicantWrap" class="mt-5 contentsWidth mx-auto">
      <div class="title">申請</div>
      <b-table
        show-empty
        :fields="heads.app"
        :items="showData.app"
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        table-class="icon-white"
        id="applicantTable"
      >
        <template v-slot:cell(name)="data">
          <b-link :to="'/application/'+data.item.typeId">{{data.item.name}}</b-link>
        </template>
        <template v-slot:cell(appDate)="data">
          <b-link :to="'/application/'+data.item.typeId" v-if="data.item.isAccepting">
            {{data.item.appDate}} (受付中)
          </b-link>
          <span v-else>
            {{data.item.appDate}}
          </span>
        </template>
        <template v-slot:cell(copyLink)="data">
          <b-link
            @click="exportPdf(data.item.typeId, 'app', data.item.name)"
            v-if="data.item.hasPrintPreview"
            class="printBtn">
            PDF出力
          </b-link>
          <div class="printBtn nonePrintView" v-else>
            PDF出力
          </div>
        </template>
        <template v-slot:empty="scope">
          <p class="none-data">申請情報はありません。</p>
        </template>
      </b-table>
    </div>
    <div id="reportWrap" class="mt-5 contentsWidth mx-auto" v-if="showData.report.length > 0">
      <div class="title">報告</div>
      <b-table
        show-empty
        :fields="heads.report"
        :items="showData.report"
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        table-class="icon-white"
        id="reportTable"
      >
       <template v-slot:cell(name)="data">
          <b-link :to="'/report/'+data.item.typeId">{{data.item.name}}</b-link>
        </template>
        <template v-slot:cell(reportDate)="data">
          <b-link :to="'/report/'+data.item.typeId" v-if="data.item.isAccepting">
            {{data.item.reportDate}} (受付中)
          </b-link>
          <span v-else>
            {{data.item.reportDate}}
          </span>
        </template>
        <template v-slot:empty="scope">
          <p class="none-data">報告情報はありません。</p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';

export default {
  name: 'GasTop',
  components: {},
  data() {
    return {
      heads: {
        app: [
          {
            key: 'name',
            label: '助成種別',
            sortable: true,
            class: 'name',
          },
          {
            key: 'year',
            label: '年度',
            sortable: false,
            class: 'year',
          },
          {
            key: 'appDate',
            label: '申請期間',
            sortable: true,
            class: 'appDate',
          },
          {
            key: 'status',
            label: 'ステータス',
            sortable: true,
            class: 'status',
          },
          {
            key: 'copyLink',
            label: '',
            sortable: false,
            class: 'copyLink',
          },
        ],
        report: [
          {
            key: 'name',
            label: '助成種別',
            sortable: true,
            class: 'name',
          },
          {
            key: 'year',
            label: '年度',
            sortable: false,
            class: 'year',
          },
          {
            key: 'reportDate',
            label: '報告期間',
            sortable: true,
            class: 'reportDate',
          },
          {
            key: 'status',
            label: 'ステータス',
            sortable: true,
            class: 'status',
          },
        ],
      },
      initData: {
        app: [],
        report: [],
        attachments: [],
      },
      showData: {
        app: [],
        report: [],
        attachments: [],
      },
      news: [],
      notification: [],
      isLatesNew: false,
    };
  },
  methods: {
    async initFetch(promiseFuncs) {
      const res = await api.all(promiseFuncs)
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (res !== false) {
        res.map((list) => {
          const listData = list.data;
          const dataType = listData.type;

          switch (dataType) {
            case 'app':
            case 'report':
            case 'attachments':
              this.setMyList(listData, dataType);
              break;
            case 'news':
              this.news = listData.newsList;
              break;
            case 'notification':
              this.notification = listData.notifications;
              this.isLatesNew = listData.isLatesNew;
              break;
            default:
              break;
          }
          return true;
        });
      }
    },
    setMyList(listData, listType) {
      this.showData[listType] = listData.list;
    },
    dataReset(initData) {
      return JSON.parse(JSON.stringify(initData));
    },
    async exportPdf(typeId, type, apptypeName) {
      this.$store.dispatch('page/onWaiting');
      const params = {
        typeId,
        type,
      };
      const requireBlob = true;
      const response = await api.send('/api/export/mypdf', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。お手数ですが、添付いただいたファイルのPDF変換をもう一度やり直すか、別の方法でPDFへの変換をお試しください。それでも解消しない場合は、事務局までお問い合わせください。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const fileName = `${ymd}_${apptypeName}.pdf`;
      download.blob(response.data, fileName);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    const promiseFuncs = [
      api.send('/api/appType/mylist'),
      api.send('/api/reportType/mylist'),
      api.send('/api/newsList'),
      api.send('/api/notification/mylist'),
    ];
    await this.initFetch(promiseFuncs);
    this.$store.dispatch('page/offLoading');

    // parfectscrollの発火
    setTimeout(() => {
      document.querySelector('#notificationArea').scrollTop = 1;
      document.querySelector('#newsArea').scrollTop = 1;
    }, 0);
  },
};
</script>

<style scoped>
  .Top{
    padding: 0 5vh;
  }
  .title {
    margin-bottom: 0.5rem;
    color: #333;
  }
  #newsArea,
  #notificationArea {
    height: 20vh;
    overflow-y: scroll;
    background-color: rgba(29, 39, 48, 0.1);
    padding: 15px;
    font-size: 16px;
  }
  #newsArea tr,
  #notificationArea tr{
    padding: 0.1em;
    text-align: left;
  }
  #newsArea td,
  #notificationArea td{
    word-break: break-all;
    white-space: pre-wrap;
  }
  #applicantTable tbody tr:nth-of-type(odd),
  #reportTable tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
  }
  #applicantWrap table,
  #reportWrap table {
    font-size: 16px;
  }
  #applicantTable td,
  #applicantTable th,
  #reportTable td,
  #reportTable th {
    font-size: 14px;
  }
  #applicantTable .th0,
  #reportTable .th0 {
    width: 25%;
  }
  #year{
    text-align: left;
  }
  #newsArea tr:first-of-type th,
  #newsArea tr:first-of-type td,
  #notificationArea tr:first-of-type th,
  #notificationArea tr:first-of-type td{
    border:none;
  }
  #newsArea th,
  #notificationArea th{
    width: 130px;
  }
  #notificationArea th{
    padding-right: 0;
  }
  .none-data{
    margin:0;
    text-align: center;
  }
  #reportWrap{
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  td.icon{
    width: 35px;
    padding-left: 0;
    padding-right: 0;
  }
</style>
<style>
  #applicantWrap .title,
  #reportWrap .title {
    color: #333;
  }
  #applicantWrap .max-w80,
  #reportWrap .max-w80 {
    max-width: 80px;
  }
  #applicantWrap .max-w120,
  #reportWrap .max-w120 {
    max-width: 120px;
    font-size: 18px;
  }
  #applicantWrap .max-w80,
  #reportWrap .max-w80,
  #applicantWrap .container-fluid,
  #reportWrap .container-fluid {
    margin: 0;
    padding: 0;
  }
  .dataWrap{
    border-bottom: solid 1px #CCCCCC;
  }
  #applicantWrap .tableHead,
  #reportWrap .tableHead {
    background: #6E6E6E;
    color: #FFF;
    border:solid 2px #FFFFFF;
    height: 40px !important;
    word-break: keep-all;
  }
  #applicantWrap .tableHead th,
  #reportWrap .tableHead th {
    border:solid 1px #CCCCCC;
  }
  td.name{
    word-break: break-all;
  }
  td.status{
    width: 130px;
  }
  td.copyLink{
    width: 160px;
  }
  td.appDate,
  td.reportDate{
    width: 480px;
  }
  td.year{
    width: 130px;
  }
  #applicantWrap tbody td,
  #reportWrap tbody td
  {
    vertical-align: middle;
  }
  .printBtn{
    border: solid 1px;
    padding: 5px;
    border-radius: 6px;
    width: 140px;
    display: block;
    font-size: 14px;
    text-align: center;
  }
  .printBtn:hover{
    text-decoration: none;
  }
  .nonePrintView{
    color: #0A8EA7;
    opacity: 0.3;
  }
</style>
